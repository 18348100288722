<template>
    <section class="position-relative">
        <div class="w-100 h-100 position-absolute container-img">
            <div class="img-background" />
        </div>
        <div class="navbar-postulacion pl-4">
            <img src="/img/auth/logo_rojo.svg" style="max-width:150px;max-height:80px;" class="ml-4 mr-4 obj-cover" />
        </div>
        <div class="container-fluid content px-0" style="height:calc(100vh - 5px);">
            <div class="row mx-0" style="height:calc(100% - 43px);">
                <div class="col">
                    <div class="row mx-0 mt-5">
                        <div class="col">
                            <vueper-slides class="no-shadow text-muted2 h-vueper" :touchable="false" autoplay :arrows="false">
                                <vueper-slide
                                v-for="slide in slides"
                                :key="slide.id"
                                >
                                    <template v-slot:content>
                                        <div class="row mx-0 justify-center">
                                            <div class="col-auto text-center">
                                                <div class="br-12 d-middle-center shadow border py-2 content-slider" style="height:82px;width:552px;">
                                                    {{ slide.text }}
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </vueper-slide>
                                <template v-slot:bullet="{ active }">
                                    <div class="row mx-0">
                                        <div class="col-auto px-0">
                                            <i class="icon-brightness-1 " :class="active ? 'text-leeche' : 'text-muted'" />
                                        </div>
                                    </div>
                                </template>
                            </vueper-slides>
                        </div>
                    </div>
                    <div class="row mx-0 my-4 justify-center postulacion-img">
                        <div class="position-relative  col px-0" :class="estado_validacion ? 'text-right' : 'text-center'">
                            <img :src="`/img/auth/${estado_validacion ? 'tendero_registrado' : 'tendero'}.svg`" style="max-width:100%;" class="obj-cover img-validate" />
                        </div>
                        <div v-if="estado_validacion" class="col">
                            <div class="shadow position-relative br-12 px-2 py-3 text-center w-100" style="max-width:280px;z-index:2">
                                <!-- Arrow -->
                                <div class="arrow-oh position-absolute" />
                                <!-- Arrow -->
                                <p class="text-leeche f-40 f-600"> !Oh Oh! </p>
                                <p class="text-muted2 f-18 mt-2">
                                    Parece que ya hay un usuario registrado con esos datos <br /> Para mayor informacion comunícate con: <br />
                                    <span class="text-red f-16"> contacto@leeche.com </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 justify-center">
                        <div class="col pr-0">
                            <div class="row mx-0 justify-center">
                                <router-link :to="{name: 'login.leechero.terminos-condiciones'}">
                                    <div class="col-auto f-16 text-muted2 f-600">
                                        <i class="icon-book-open-variant" /> Términos y condiciones
                                    </div>
                                </router-link>
                                <router-link :to="{name: 'login.leechero.politica-privacidad'}">
                                    <div class="col-auto f-16 text-muted2 f-600">
                                        <i class="icon-lock" /> Política de privacidad
                                    </div>
                                </router-link>
                                <router-link :to="{name: 'login.leechero.preguntas-frecuentes'}">
                                    <div class="col-auto f-16 text-muted2 f-600">
                                        <i class="icon-help" /> Preguntas frecuentes
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <ValidationObserver ref="validador" v-slot="{ invalid }">
                        <div
                        id="postulacion"
                        v-loading="cargando.postulacion"
                        class="row justify-center"
                        element-loading-text="Cargando la información de la tienda, por favor espere..."
                        >
                            <div class="box border input-pr br-12 bg-white custom-scroll overflow-auto" style="">
                                <!-- Header box -->
                                <div class="row mx-0 justify-center mt-3">
                                    <div class="col-auto text-center">
                                        <img src="/img/auth/logo_rojo.svg" class="obj-cover" style="max-width:200px;max-height:50px;" />
                                        <p class="f-600 mt-3 text-general">
                                            En Leeche queremos saber de ti
                                        </p>
                                    </div>
                                </div>
                                <!-- Header Box -->
                                <div class="custom-scroll2 my-2">
                                    <!-- Foto de la tienda -->
                                    <div class="row mx-0 mx-5 pt-3 text-muted2 f-15">
                                        <div class="col position-relative">
                                            <p class="text-center text-general my-2 f-500">Foto</p>
                                            <div class="row mx-0 px-1 justify-center">
                                                <slim-cropper
                                                ref="cropLogo"
                                                :options="configuracionCropper"
                                                class="border cr-pointer br-12"
                                                style="width:200px;background:#F8F9FF;"
                                                >
                                                    <div slot="label">
                                                        <img src="/img/modales/camera.svg" />
                                                    </div>
                                                </slim-cropper>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Info Dueño -->
                                    <div class="row mx-0 justify-center pt-3">
                                        <div class="col-10" style="width:337px;">
                                            <div class="row mx-3 text-general f-15">
                                                ¿Cómo te llamas?
                                            </div>
                                            <ValidationProvider v-slot="{ errors }" rules="required" vid="propietario_nombre" name="nombre del dueño">
                                                <el-input
                                                v-model="model.propietario_nombre"
                                                class="w-100"
                                                placeholder="Nombre y apellido"
                                                maxlength="60"
                                                show-word-limit
                                                />
                                                <span class="text-danger f-11"> {{ errors[0] }} </span>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="row mx-0 justify-center pt-3">
                                        <div class="col-10">
                                            <div class="f-15 mx-3 text-general row">
                                                ¿Cuándo naciste?
                                            </div>
                                            <el-date-picker v-model="model.propietario_nacimiento" type="date" format="dd - MM - yyyy" class="w-100" value-format="yyyy-MM-dd" :picker-options="pickerOptions" />
                                        </div>
                                    </div>
                                    <div class="row mx-0 justify-center pt-3">
                                        <div class="col-10 d-middle-center">
                                            <el-radio-group v-model="model.propietario_genero">
                                                <el-radio class="radio-red" :label="2">
                                                    Mujer
                                                </el-radio>
                                                <el-radio class="radio-red" :label="1">
                                                    Hombre
                                                </el-radio>
                                                <el-radio class="radio-red" :label="3">
                                                    Otro
                                                </el-radio>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <!-- Ubibacion -->
                                    <div class="row mx-0 justify-center pt-3">
                                        <div class="col-10 f-16 my-2 text-general">
                                            ¿Dónde estás ubicado?
                                        </div>
                                        <div class="col-10 position-relative" style="width:337px;">
                                            <div class="row mx-3 text-general f-15">
                                                País
                                            </div>
                                            <ValidationProvider v-slot="{ errors }" rules="required" name="país">
                                                <el-select
                                                v-model="proyecto.seleccion.pais"
                                                :disabled="!existeMasDeUnPais"
                                                placeholder="Seleccione un país"
                                                filterable
                                                class="w-100"
                                                >
                                                    <el-option
                                                    v-for="(item, i) in proyecto.paises"
                                                    :key="i"
                                                    :label="item.nombre"
                                                    :value="item.id"
                                                    />
                                                </el-select>
                                                <span class="text-danger f-11"> {{ errors[0] }} </span>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="row mx-0 justify-center pt-3">
                                        <div class="col-10 position-relative" style="width:337px;">
                                            <div class="row mx-3 text-general text-general f-15">
                                                Estado
                                            </div>
                                            <ValidationProvider v-slot="{ errors }" rules="required" name="estado">
                                                <el-select
                                                v-model="proyecto.seleccion.estado"
                                                :disabled="!existeMasDeUnEstado"
                                                placeholder="Seleccione un estado"
                                                class="w-100"
                                                filterable
                                                >
                                                    <el-option
                                                    v-for="(item, i) in proyecto.estados"
                                                    :key="i"
                                                    :label="item.nombre"
                                                    :value="item.id"
                                                    />
                                                </el-select>
                                                <span class="text-danger f-11"> {{ errors[0] }} </span>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="row mx-0 justify-center pt-3">
                                        <div class="col-10 position-relative" style="width:337px;">
                                            <div class="row mx-3 text-general f-15">
                                                Ciudad
                                            </div>
                                            <ValidationProvider v-slot="{ errors }" rules="required" vid="idm_ciudad" name="ciudad">
                                                <el-select
                                                v-model="model.idm_ciudad"
                                                :disabled="!existeMasDeUnaCiudad"
                                                placeholder="Seleccione una ciudad"
                                                class="w-100"
                                                filterable
                                                >
                                                    <el-option
                                                    v-for="(item, i) in proyecto.ciudades"
                                                    :key="i"
                                                    :label="item.nombre"
                                                    :value="item.id"
                                                    />
                                                </el-select>
                                                <span class="text-danger f-11"> {{ errors[0] }} </span>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="row mx-0 justify-center pt-3">
                                        <div class="col-10 position-relative" style="width:337px;">
                                            <div class="row mx-3 text-general f-15">
                                                Dirección
                                            </div>
                                            <ValidationProvider v-slot="{ errors }" rules="required" vid="direccion" name="dirección">
                                                <el-input
                                                v-model="model.direccion"
                                                class="w-100"
                                                placeholder="Cra. 80 #40-90"
                                                maxlength="70"
                                                show-word-limit
                                                @change="buscarDireccion"
                                                />
                                                <span class="text-danger f-11"> {{ errors[0] }} </span>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="row mx-0 justify-center pt-3">
                                        <div class="col-10 position-relative" style="width:337px;">
                                            <div class="row mx-3 text-general f-15">
                                                Complemento (opcional)
                                            </div>
                                            <ValidationProvider v-slot="{ errors }" vid="direccion_anexo" name="complemento">
                                                <el-input
                                                v-model="model.direccion_anexo"
                                                class="w-100"
                                                placeholder="Local #123"
                                                maxlength="40"
                                                show-word-limit
                                                @change="buscarDireccion"
                                                />
                                                <span class="text-danger f-11"> {{ errors[0] }} </span>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="row mx-0 justify-center pt-3">
                                        <div class="col-10 position-relative" style="width:337px;">
                                            <div class="row mx-3 text-general f-15">
                                                Barrio / Colonia
                                            </div>
                                            <ValidationProvider v-slot="{ errors }" vid="barrio" rules="required|max:30" name="barrio">
                                                <el-input
                                                v-model="model.barrio"
                                                class="w-100"
                                                placeholder="¿En qué barrio se ubica?"
                                                maxlength="30"
                                                show-word-limit
                                                />
                                                <span class="text-danger f-11"> {{ errors[0] }} </span>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <!-- Ubibacion -->
                                    <div class="row mx-0 justify-center pt-3">
                                        <div class="col-10 px-0 position-relative my-3 cr-pointer">
                                            <div class="position-absolute h-100 w-100" style="left:0px;top:0px;z-index:3;" @click="abrirModalMapa()" />
                                            <mapa ref="mapaPeq" :coordenadas="coordenadas" :buscar="getDireccionCon" @actualizar="actualizarCoordenadas" />
                                            <!-- <img :src="rutaImagenMapa()" class="w-100 cr-pointer" @click="abrirModalMapa" /> -->
                                            <p class="f-13 mt-2 text-gris2">
                                                Haga click aquí, para seleccionar la ubicación de su tienda
                                            </p>
                                        </div>
                                    </div>

                                    <!-- Contactos -->
                                    <div v-if="login == 2" class="row mx-0 justify-center pt-3">
                                        <div class="col-10 my-2 text-general">
                                            ¿Cómo te podemos contactar?
                                        </div>
                                        <div class="col-10" style="width:337px;">
                                            <div class="row mx-3 text-general f-15">
                                                Número celular
                                            </div>
                                            <ValidationProvider v-slot="{ errors }" :rules="reglas.telefono(true)" vid="telefono" name="teléfono">
                                                <el-input
                                                v-model="model.telefono"
                                                type="tel"
                                                class="w-100"
                                                placeholder="301234567"
                                                maxlength="18"
                                                show-word-limit
                                                >
                                                    <template slot="prepend">
                                                        <img :src="getBandera" alt="" width="25" height="25" class="rounded-circle" />
                                                        <span class="text-danger mx-1">+{{ getPrefijo }}</span>
                                                    </template>
                                                </el-input>
                                                <span class="text-danger f-11"> {{ errors[0] || errorUsuarioTomado.telefono }} </span>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div v-if="login == 1" class="row mx-0 justify-center pt-3">
                                        <div class="col-10" style="width:337px;">
                                            <div class="row mx-3 text-general f-15">
                                                Correo electrónico
                                            </div>
                                            <ValidationProvider v-slot="{ errors }" rules="required|email" vid="correo" name="correo">
                                                <el-input
                                                v-model="model.correo"
                                                type="email"
                                                class="w-100"
                                                placeholder="ejemplo@correo.com"
                                                maxlength="70"
                                                show-word-limit
                                                >
                                                    <i
                                                    v-if="errors[0] || errorUsuarioTomado.correo"
                                                    slot="suffix"
                                                    class="text-red icon-info-circled-alt f-18 p-2 d-flex align-items-center"
                                                    />
                                                </el-input>
                                                <span class="text-danger f-11"> {{ errors[0] || errorUsuarioTomado.correo }} </span>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="row mx-0 justify-center pt-3">
                                        <div class="col-10 position-relative" style="width:337px;">
                                            <div class="row mx-3 text-general f-15">
                                                Cúentanos más de ti
                                            </div>
                                            <ValidationProvider v-slot="{ errors }" rules="required" vid="descripcion" name="descripción">
                                                <el-input
                                                v-model="model.descripcion"
                                                type="textarea"
                                                rows="4"
                                                class="w-100"
                                                placeholder="Describa a qué se dedica su tienda"
                                                maxlength="250"
                                                show-word-limit
                                                />
                                                <span class="text-danger f-11"> {{ errors[0] }} </span>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <!-- Imagenes de la tienda -->
                                    <div class="row mx-0 pt-3 text-muted2 f-15 justify-center">
                                        <div class="col-10 d-middle my-3">
                                            <el-checkbox v-model="tienda" class="check-red" label="1">
                                                Tengo una tienda
                                            </el-checkbox>
                                        </div>
                                    </div>
                                    <template v-if="tienda">
                                        <div>
                                            <div class="row mx-0 justify-center pt-3">
                                                <div class="col-10" style="width:337px;">
                                                    <div class="row mx-3 text-general f-15">
                                                        Nombre de la tienda
                                                    </div>
                                                    <ValidationProvider v-slot="{ errors }" rules="required" vid="nombre" name="nombre de la tienda">
                                                        <el-input
                                                        v-model="model.nombre" class="w-100"
                                                        placeholder="¿Cómo se llama tu tienda?"
                                                        maxlength="60"
                                                        show-word-limit
                                                        />
                                                        <span class="text-danger f-11">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <div class="row mx-0 justify-center pt-3">
                                                <div class="col-10 position-relative" style="width:337px;">
                                                    <div class="row mx-3 text-general f-15">
                                                        Número de identificación fiscal (Opcional)
                                                    </div>
                                                    <ValidationProvider v-slot="{ errors }" rules="numeric" vid="nit" name="número de identificación fiscal">
                                                        <el-input
                                                        v-model.number="model.nit"
                                                        class="w-100"
                                                        placeholder="123456789"
                                                        maxlength="10"
                                                        show-word-limit
                                                        />
                                                        <span class="text-danger f-11"> {{ errors[0] }} </span>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <div class="row mx-0 justify-center pt-3">
                                                <div class="col-10 position-relative" style="width:337px;">
                                                    <div class="row mx-3 text-general f-15">
                                                        Soporte de la identificación fiscal (Opcional)
                                                    </div>
                                                    <el-upload
                                                    ref="archivo"
                                                    :auto-upload="false"
                                                    :multiple="false"
                                                    :on-change="capturarSubidaArchivo"
                                                    accept="image/*,application/pdf"
                                                    class="upload-demo w-100"
                                                    action="#"
                                                    >
                                                        <button type="button" class="btn btn-cancelar bg-whitesmoke2 w-100 br-10">
                                                            Cargar Soporte
                                                        </button>
                                                    </el-upload>
                                                    <!-- opcion por si pioden el formato del archivo cargado -->
                                                    <!-- <div class="w-100 br-5 bg-gr-general text-white mt-2 d-flex d-middle-center position-relative z-1" style="height:30px;z-index:1;">
                                                <i class="icon-doc-inv f-22 mr-2" />
                                                <p class="tres-puntos">{{ adjuntoSoporte.name }}</p>
                                                <i class="icon-cancel ml-auto" />
                                            </div> -->
                                                    <p class="text-muted f-12 mt-2">Sólo archivos PDF e imágenes</p>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <div class="row mx-0 pt-3 text-muted2 f-15 justify-center">
                                        <div class="mt-2">
                                            <p class="pl-3 text-center text-general">¿Hay algo que nos quieras mostrar?</p>
                                        </div>
                                    </div>
                                    <div class="row mx-0 pt-3 text-muted2 f-15 justify-center ">
                                        <div class="col pr-2">
                                            <p class="text-center mb-2"><i class="f-10 pl-3">Tamaño recomendado 140 * 140 </i></p>
                                            <div class="row mx-0 justify-center">
                                                <div class="col text-center">
                                                    <el-upload
                                                    action="#"
                                                    :auto-upload="false"
                                                    :before-upload="validarImagenCargada"
                                                    :on-change="capturarSubidaImagenes"
                                                    list-type="picture-card"
                                                    :file-list="adjuntosImagenes"
                                                    accept="image/*"
                                                    class="row justify-center mx-0 upload-login"
                                                    :multiple="true"
                                                    :limit="4"
                                                    >
                                                        <img
                                                        slot="default"
                                                        src="/img/icons/add-image.svg"
                                                        />
                                                        <div slot="file" slot-scope="{file}">
                                                            <span class="el-upload-list__item-actions">
                                                                <span
                                                                class="el-upload-list__item-delete"
                                                                @click="handleRemove(file)"
                                                                >
                                                                    <i class="el-icon-delete" />
                                                                </span>
                                                            </span>

                                                            <img
                                                            class="el-upload-list__item-thumbnail"
                                                            :src="file.url" alt=""
                                                            />
                                                        </div>
                                                    </el-upload>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Datos Leechero -->
                                    <!-- Datos Leechero -->
                                    <div class="row mx-0 justify-center pt-4">
                                        <div class="col-10 mb-2 f-16 text-general">
                                            Crea tu contraseña
                                        </div>
                                        <div class="col-10 position-relative" style="width:337px;">
                                            <div class="row mx-3 text-general f-15">
                                                Contraseña
                                            </div>
                                            <ValidationProvider v-slot="{ errors }" :rules="reglas.contrasena" vid="password" name="contraseña">
                                                <el-input
                                                v-model="model.password"
                                                class="w-100"
                                                placeholder="Escriba una contraseña"
                                                maxlength="200"
                                                minlength="8"
                                                show-password
                                                />
                                                <span class="text-danger f-11"> {{ errors[0] }} </span>
                                                <p class="text-muted f-12 mt-2">La contraseña debe tener mínimo 8 cacacteres y contener al menos 1 letra</p>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="row mx-0 justify-center pt-3">
                                        <div class="col-10 position-relative" style="width:337px;">
                                            <div class="row mx-3 text-general f-15">
                                                Confirmar contraseña
                                            </div>
                                            <ValidationProvider v-slot="{ errors }" rules="required_if:password|confirmed:password" name="confirmar contraseña">
                                                <el-input
                                                v-model="validacionContrasena"
                                                class="w-100"
                                                placeholder="Repita su contraseña"
                                                show-password
                                                />
                                                <span class="text-danger f-11"> {{ errors[0] }} </span>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="row mx-0 justify-center pt-3">
                                        <div class="col-10 d-middle-center" style="width:337px;">
                                            <el-checkbox v-model="aceptaTerminosCondiciones" class="check-red" />
                                            <router-link :to="{ name: 'login.terminos-condiciones', params: {} }" class="f-14 ml-2 text-muted2" target="_blank">
                                                He leído y acepto los <span class="text-general"> términos y <br /> condiciones y Pólitica de privacidad  </span>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                                <!-- Footer Box -->
                                <div class="row mx-0 mt-3 px-5 justify-center">
                                    <div class="col-auto text-center" style="width:337px;">
                                        <button
                                        :disabled="invalid || !aceptaTerminosCondiciones"
                                        class="btn btn-block br-12 p-1 py-2 d-flex d-middle-center text-white bg-leeche border-0"
                                        @click="intentarPostularTienda"
                                        >
                                            Quiero sumarme
                                        </button>
                                    </div>
                                </div>
                                <div class="row mx-0 my-4 justify-center">
                                    <router-link to="/login" class="text-leeche f-18 f-500">
                                        Ya tengo un usuario
                                    </router-link>
                                </div>
                                <!-- Footer Box -->
                            </div>
                        </div>
                    </ValidationObserver>
                </div>
                <div class="col-auto" />
                <div class="col-auto" />
            </div>
        </div>
        <!-- Partials -->
        <mapa-google-modal
        ref="modalMapaGoogle"
        class="centrar-modal"
        postal
        busqueda
        :buscar="getDireccionCon"
        :coordenadas-entrada="coordenadasCiudad"
        @actualizar="actualizarCoordenadasCiudad"
        />
    </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Aws from '~/services/aws';
import ServicioApi from '~/services/tiendas/postulacion';
import moment from 'moment'

const VUEX_RUTA = 'auth'

export default {
    name: 'TiendaPostulacion',
    layout: 'postulacion',
    components: {
        mapa: () => import('./components/mapa'),
    },
    data(){
        return {
            tienda: false,
            date: '',
            value: '',
            estado_validacion: false,
            aceptaTerminosCondiciones: false,
            proyecto: {
                datos: {},
                paises: [],
                estados: [],
                ciudades: [],
                seleccion: {
                    pais: 0,
                    estado: 0,
                    ciudad: 0
                }
            },
            reglas: {
                telefono: required => ({
                    required,
                    max:18,
                    numeric: true,
                }),
                contrasena: {
                    required: true,
                    min: 8,
                    max: 200,
                    regex: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*.?\-_\/&:,;{}+*'¿¡!]?)([A-Za-z\d@$!.%*?\-_\/&:,;{}+*'¿¡!]){8,200}$/
                }
            },
            tiposTiendas: [],
            coordenadasCiudad: { lng: 0, lat: 0 },
            coordenadas: { lat: 0, lng: 0 },
            validacionContrasena: '',
            errorUsuarioTomado: {
                correo: '',
                telefono: ''
            },
            model: _.cloneDeep(this.$store.state.register.model),
            adjuntoSoporte: File,
            adjuntosImagenes: [],
            cargando: {
                postulacion: false,
                archivo: false
            },
            configuracionCropper: {
                ratio: '1:1',
                label:'Subir imagen de perfil',
                size: { width: 800, height: 800 },
                minSize: { width: 100, height: 100 },
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok'
            },
            slides: [
                {text: 'Leeche responde por productos defectuosos, no tendrás nada de qué preocuparte'},
                {text: 'Con Leeche podrás conseguir un ingreso extra por cada producto que vendas'},
                {text: 'Convierte a tus vecinos en clientes. ¡Con Leeche todos ganan!'},
            ],
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() > Date.now()
                },
            },

        }
    },
    computed: {
        ...mapGetters({
            idUser: 'register/idUser',
            login: 'register/getLogin',
            usuario: 'register/getUsuario',
        }),
        existeMasDeUnPais(){
            return this.existeMasUnElemento('paises')
        },
        existeMasDeUnEstado(){
            return this.existeMasUnElemento('estados')
        },
        existeMasDeUnaCiudad(){
            return this.existeMasUnElemento('ciudades')
        },
        getBandera(){
            if(_.get(this.proyecto, 'seleccion.pais', false)){
                const find = this.proyecto.paises.find(el => el.id == this.proyecto.seleccion.pais)
                return find.bandera_firmada
            }
            return ''
        },
        getPrefijo(){
            if(_.get(this.proyecto, 'seleccion.pais', false)){
                const find = this.proyecto.paises.find(el => el.id == this.proyecto.seleccion.pais)
                return find.indicativo ?? '00'
            }
            return '00'
        },
        getDireccionCon(){
            const { ciudad = '' } = this.proyecto.ciudades.find(x => x.id === this.model.idm_ciudad) || {};
            const { nombre: pais = '' } = this.proyecto.paises.find(x => x.id === this.proyecto.seleccion.pais) || {};
            return [this.model.direccion, ciudad, pais].filter(el => el).join(',')
        },
        modelo(){
            const model = _.cloneDeep(this.$store.state.register.model)
            this.actualizarCoordenadasCiudad(model)
            return model
        }
    },
    watch: {
        'proyecto.datos.id'(id){
            this.consultarPaisesProyecto(id)
        },
        'proyecto.paises'(valores){
            this.tomarPrimerRegistroArray(valores, 'pais', this.model.idm_pais)
        },
        'proyecto.estados'(valores){
            this.tomarPrimerRegistroArray(valores, 'estado', this.model.idm_estado)
        },
        'proyecto.ciudades'(valores){
            this.model.idm_ciudad = this.modelo.idm_ciudad ?? null
            this.tomarPrimerRegistroArray(valores, 'ciudad', this.model.idm_ciudad)
        },
        'proyecto.seleccion.pais'(pais){
            this.consultarEstadosProyecto(pais)
        },
        'proyecto.seleccion.estado'(estado){
            this.consultarCiudadesProyecto(estado)
        },
        'proyecto.seleccion.ciudad'(ciudad){
            this.model.idm_ciudad = ciudad
        },
        'model.idm_ciudad'(ciudad){
            this.capturarCoordenadasCiudad(ciudad)
        },
        'model.correo'(valor){
            this.validarCampoVerificacion(valor, 'correo')
        },
        'model.telefono'(valor){
            this.validarCampoVerificacion(valor, 'telefono')
            this.model.telefono_celular = valor
        },
        modelo(val){
            this.model = val
        }
    },
    async mounted(){
        await this.consultarProyecto();
        this.putImageOnCropper(this.modelo)
        // this.consultarTiposTiendas();
    },
    methods: {
        ...mapActions({
            intentarIniciarSesion: `${VUEX_RUTA}/intentarIniciarSesion`,
        }),
        async consultarProyecto(){
            try {
                const { data: { data } } = await ServicioApi.consultarProyecto()
                this.proyecto.datos = data
            } catch (error){
                this.error_catch(error)
            }
        },
        async consultarTiposTiendas(){
            try {
                const { data: { data } } = await ServicioApi.consultarTiposTiendas({
                    id_proyecto: this.proyecto.datos.id
                });
                this.tiposTiendas = data;
            } catch (error){
                this.error_catch(error);
            }
        },
        async consultarPaisesProyecto(proyecto){
            try {
                const { data: { data } } = await ServicioApi.consultarPaises(proyecto)
                this.proyecto.paises = data
            } catch (error){
                this.error_catch(error)
            }
        },
        async consultarEstadosProyecto(pais = null){
            if (!pais) return
            try {
                const { data: { data } } = await ServicioApi.consultarEstados({
                    proyecto: this.proyecto.datos.id,
                    pais
                })
                this.proyecto.estados = data
            } catch (error){
                this.error_catch(error)
            }
        },
        async consultarCiudadesProyecto(estado = null){
            if (!estado) return
            try {
                const { data: { data } } = await ServicioApi.consultarCiudades({
                    proyecto: this.proyecto.datos.id,
                    estado
                })
                this.proyecto.ciudades = data
            } catch (error){
                this.error_catch(error)
            }
        },
        tomarPrimerRegistroArray(valores, objetivo, defecto = null){
            if (!_.isEmpty(valores)) this.proyecto.seleccion[objetivo] = defecto ?? valores[0].id
        },
        existeMasUnElemento(indice){
            return this.proyecto[indice].length > 1
        },
        capturarCoordenadasCiudad(ciudad){
            const { latitud, longitud } = this.proyecto.ciudades.find(x => x.id === ciudad);
            this.coordenadasCiudad = {
                lat: +(latitud),
                lng: +(longitud)
            };
            this.coordenadas = {
                lat: +(latitud),
                lng: +(longitud)
            };
            this.$refs.mapaPeq.setPosition()

            console.log('this.coordenadasCiudad', {...this.coordenadasCiudad});
            console.log('this.coordenadas', {...this.coordenadas});
            console.log({ latitud, longitud });


            this.model = {
                ...this.model,
                latitud,
                longitud
            };
        },
        validarCampoVerificacion(valor, tipo){
            if(!_.isEmpty(valor)) this.debouncer({ tipo, valor })
        },
        debouncer: _.debounce(function(datos){
            this.validarSiUsuarioExiste(datos)
        }, 200),
        async validarSiUsuarioExiste({ tipo, valor: usuario }){
            try {
                const { data: { existe } } = await ServicioApi.verificarSiUsuarioExiste({ usuario })
                this.estado_validacion = existe;
                this.errorUsuarioTomado[tipo] = existe ? `El ${tipo} ya está en uso` : ''
            } catch (error){
                this.error_catch(error)
            }
        },
        validarImagenCargada(archivo){
            const nombreEsCorrecto = archivo.name.length <= 100;
            const esImagen = archivo.type.split('/')[0] === 'image';
            const pesaMenos2M = archivo.size / 1024 / 1024 <= 2;

            if (!esImagen)
                this.notificacion('Inválido', 'Sólo se permiten imágenes', 'error');

            if (!nombreEsCorrecto)
                this.notificacion(
                    'No válido',
                    `¡El nombre del archivo ${archivo.name} excede los 100 caracteres!`,
                    'error'
                );

            if (!pesaMenos2M)
                this.notificacion(
                    'No válido',
                    'El peso del archivo sobrepasa los 2Mb',
                    'error'
                );

            return nombreEsCorrecto && esImagen && pesaMenos2M;
        },
        capturarSubidaImagenes(archivo, listaArchivos){
            listaArchivos = [...listaArchivos, archivo]
            this.adjuntosImagenes = [...this.adjuntosImagenes, archivo];
        },
        async capturarSubidaArchivo(archivo, listaArchivos){
            if (archivo.name.length > 100){
                this.removerArchivoLista(listaArchivos, archivo);
                return this.notificacion('No válido', `¡El nombre del archivo ${archivo.name} excede los 100 caracteres!`, 'error')
            }

            const clasificacionArchivos = {
                'application/pdf': {
                    tipo: 1,
                    tamano: 20
                },
                image: {
                    tipo: 2,
                    tamano: 2
                },
            };

            const tipoArchivo = clasificacionArchivos[archivo.raw.type.split('/')[0]] || clasificacionArchivos[archivo.raw.type];

            if (!tipoArchivo){
                this.removerArchivoLista(listaArchivos, archivo);
                return this.notificacion('Inválido', 'Sólo los archivos tipo PDF e imágenes están permitidos', 'error');
            }

            const pesoArchivoValido = archivo.size / 1024 / 1024 <= tipoArchivo.tamano;

            if (!pesoArchivoValido){
                this.removerArchivoLista(listaArchivos, archivo);
                return this.notificacion(
                    'No válido',
                    `El peso del archivo sobrepasa los ${tipoArchivo.tamano}Mb`,
                    'error'
                );
            }

            this.adjuntoSoporte = archivo.raw;
        },
        removerArchivoLista: (array, archivo) => array.splice(array.indexOf(archivo), 1),
        async subirSoporteNit(){
            if (_.isEmpty(this.adjuntoSoporte)) return;

            let that = this;

            const Key = `tiendas/${Aws.getHash()}.${this.adjuntoSoporte.name.split('.').pop()}`

            const { Key: archivo } = await Aws.getInstance()
                .upload({
                    Key,
                    ContentType: this.adjuntoSoporte.type,
                    Body: this.adjuntoSoporte
                })
                .on('httpUploadProgress', uploadEvent => {
                    that.cargando.archivo = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
                })
                .promise();

            this.model.nit_soporte = archivo;
            this.cargando.archivo = 0;
        },
        async obtenerImagenesTienda(){
            if (_.isEmpty(this.adjuntosImagenes)) return;

            this.model.imagenes = [];

            for await (let x of this.adjuntosImagenes){
                const base64 = await this.generarBase64(x.raw);

                this.model.imagenes.push(base64);
            }
        },
        obtenerLogoTienda(){
            this.model.logo = this.$refs.cropLogo.instanciaCrop.dataBase64.output.image;
        },
        generarBase64: async archivo => {
            const lector = new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(archivo);
                reader.onload = () => resolve(reader.result);
            });

            return await lector;
        },
        async intentarPostularTienda(){
            try {
                this.alternarCargando();
                await this.subirSoporteNit();
                await this.obtenerLogoTienda();
                await this.obtenerImagenesTienda();

                this.model.usuario = this.idUser
                let campo = this.login == 1 ? 'telefono' : 'correo'
                this.model[campo] = this.usuario
                const { data: { data: { correo: usuario } } } = await ServicioApi.postularTienda(this.model);
                this.iniciarSesion(usuario);
            } catch (error){
                this.error_catch(error)
            } finally {
                this.alternarCargando();
            }
        },
        alternarCargando(){
            this.cargando.postulacion = !this.cargando.postulacion;
        },
        async iniciarSesion(usuario){
            try {
                await this.intentarIniciarSesion({ usuario, contrasena: this.model.password });
                this.redirigirAlInicio();
            } catch (error){
                this.error_catch(error);
            }
        },
        redirigirAlInicio(){
            // this.$router.push({ name: 'tendero.home'});
            this.$router.push({ name: 'login.stop'});
        },
        abrirModalMapa(){
            this.$refs.modalMapaGoogle.toggle()
        },
        actualizarCoordenadasCiudad({ latitud, longitud }){
            if(!latitud && !longitud) return
            setTimeout(() => {
                this.model.latitud = latitud
                this.model.longitud = longitud
                this.coordenadas = {
                    lat: +(latitud),
                    lng: +(longitud)
                };
                this.coordenadasCiudad = {
                    lat: +(latitud),
                    lng: +(longitud)
                };
                this.$refs.mapaPeq.setPosition()
            }, 500);
        },
        putImageOnCropper(model){
            if (_.isEmpty(model.imagen)) return
            this.$refs.cropLogo.set_image(model.imagen)
        },
        actualizarCoordenadas({ latitud, longitud }){
            this.coordenadasCiudad = {
                lat: +(latitud),
                lng: +(longitud)
            }
            this.model.latitud = latitud
            this.model.longitud = longitud
        },
        buscarDireccion(){
            this.$refs.mapaPeq.accionBuscar();
        },
        handleRemove(file, fileList){
            this.adjuntosImagenes = this.adjuntosImagenes.filter(el => el.uid != file.uid)
        },
    },
}
</script>
<style lang="scss" scoped>
a{ text-decoration: none !important;}
.text-green{ color: #A7CA3A;}
.text-red{ color:#FF3333; }
.navbar-postulacion{
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    width: calc(100vw);
    height: 88px;
    background-color: #FFFFFF;
    //box-shadow: 0px 3px 6px #00000029;
    border-radius: 0px 0px 0px 20px;
}
.box{
    width:487px;
    box-shadow:0px 3px 6px #00000080;
    height:calc(100vh - 311px);
}
.br-12{ border-radius: 12px; }

.scroll-propuesta::-webkit-scrollbar-track{ height: 6px; width:10px; -webkit-box-shadow:inset 0 0 6px grey; background-color:var(--gr-red); border-radius: 5px; }
.scroll-propuesta::-webkit-scrollbar{ background-color:transparent; width:10px; height: 6px; border-radius: 5px; }
.scroll-propuesta::-webkit-scrollbar-thumb{ -webkit-box-shadow:inset 0 0 6px red; background-color:red; border-radius: 5px; }

.content{ padding-top: 100px; }
.text-title{ font-size: 35px; }
.subtext-title{ font-size: 30px; }
.img-validate{
    height:calc(100vh - 480px);
}
@media (max-width: 1400px) {
  .text-title {
    font-size: 30px;
  }
  .subtext-title{
      font-size: 25px;
  }
}
@media (max-width: 1300px){
    .content-slider{
        width: 425px !important;
    }
}
@media (max-width: 1047px) {
    .content-slider{
        width: 325px !important;
    }
}
@media (max-width: 1024px) {
    .img-validate{
        height: 280px !important;
    }
}
@media (max-height: 900px){
    .box{
        height:calc(100vh - 205px) !important;
    }
}
@media (max-height: 720px){
    .box{
        height:calc(100vh - 130px) !important;
    }
     .img-validate{
        height:calc(100vh - 400px);
    }
}
@media(max-height: 625px){
    .h-vueper{
        height: 110px;
    }
    .img-validate{
        height:calc(100vh - 340px);
    }
}

.img-background{
    background-image: url('/img/auth/onda_postulate.svg');
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
}
.arrow-oh{
    border-left: 25px solid transparent;
    width: 0px;
    height: 0px;
    border-top: 25px solid transparent;
    border-right: 25px solid white;
    left: -46px;
    top: 85px;
    z-index: 1;
    //box-shadow: 0px 3px 6px #00000029;
    border-bottom: 25px solid transparent;
    /* transform: rotate( 180deg); */
}
.gm-control-active{
    display: none !important;
}
</style>
