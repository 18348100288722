<template>
    <section>
        <div class="navbar-informativaLogin bg-leeche d-flex d-middle">
            <div class="col-auto px-5" />
            <router-link :to="{name: 'login.form.admin'}" class="none-router text-white cr-pointer">
                <img src="/img/auth/logo_blanco.svg" style="max-width:150px;max-height:80px;" class="ml-4 mr-4" alt="" />
            </router-link>
            <div class="col">
                <footer-faqs />
            </div>
            <p class="f-600 ml-auto text-white mr-5 f-18 cr-pointer" @click="goTo()">
                {{ esLeechero ? 'Leeche': 'Leechero' }}
            </p>
        </div>
        <div class="row mx-0 pt-3 pl-5" style="margin-top:88px;">
            <div class="col-auto px-4" />
            <div class="col-auto px-4">
                <i class="text-leeche icon-left f-22 cr-pointer" @click="$router.push({name: 'general'})" />
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-8 pl-5">
                <div class="scroll-propuesta" style="height:calc(100vh - 154px);overflow: auto;">
                    <div class="row mx-0 pt-3 text-muted2 f-22 justify-center">
                        <div class="col-auto">
                            Política de Protección y Tratamiento de Datos Personales "{{ esLeechero ? $config.proyecto : $config.vendedor }}".
                            <div class="row mx-0 justify-content-between">
                                <div class="col-auto f-15 text-muted2">
                                    {{ $config.proyecto }}
                                </div>
                                <div class="col-auto f-15 text-muted2">
                                    Actualizado: {{ tiempoActualizacion }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Contenido -->
                    <div v-for="(item, index) in datos.faqs" :id="item.titulo" :key="index" class="row mx-0 mt-5 mb-3">
                        <div class="col-12 f-17 text-muted2 f-600">
                            <p v-text="item.titulo" />
                        </div>
                        <div class="col-12 f-15 text-muted2 px-4">
                            <p style="white-space:pre-line;" v-text="item.texto.texto" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="custom-scroll overflow-auto" style="height:calc(100vh - 154px)">
                    <div
                    v-for="(item, index) in anclas"
                    :key="index"
                    class="row mx-0 text-muted2 f-17 f-600 px-3 cr-pointer my-2"
                    :class="{ 'mt-5': esElPrimero(anclas, item), 'active-ancle f-600': ancla_activa == index }"
                    >
                        <a
                        :href="`#${item}`"
                        @click="ancla_activa = index"
                        v-text="item"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import faqsInMixin from '~/mixins/faqs/informacion';

const PAYLOAD = {
    tipo: 3,
    destino: 2,
    modoLectura: 1
};

export default {
    name: 'TerminosCondicionesLogin',
    layout: 'informativaLogin',
    mixins: [faqsInMixin],
    data(){
        return{
            ancla_activa: null,
        }
    },
    computed: {
        esLeechero(){
            return this.$route.name.match('leechero')
        },
        payload(){
            let destino = this.esLeechero ? 1 : 2
            return {
                tipo: 3,
                destino,
                modoLectura: 1
            }
        }
    },
    watch: {
        "$route.name": {
            deep: true,
            handler(){
                this.consultarFaqs(this.payload);
            }
        }
    },
    mounted(){
        this.consultarFaqs(this.payload);
    },
    methods: {
        goTo(){
            let name = this.esLeechero ? 'login.cliente.politica-privacidad' : 'login.leechero.politica-privacidad'
            this.$router.push({ name })
        }
    }
}
</script>

<style lang="scss" scoped>
a{
    color: #5D5D5D;
    text-decoration: none !important;
    &:active{
        color: var(--text-general);
        font-weight: bold;
    }
}
.active-ancle{
    a{
        color: var(--text-general) !important;
        text-decoration: none;
        &:active{
            color: var(--text-general) !important;
            font-weight: bold;
        }
    }
}
.navbar-informativaLogin{
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1;
    width: calc(100vw);
    height: 88px;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0px 0px 0px 20px;
}
.scroll-propuesta::-webkit-scrollbar-track{ height: 6px; width:0.5px; -webkit-box-shadow:inset 0 0 6px transparent; background-color:transparent; border-radius: 5px; }
.scroll-propuesta::-webkit-scrollbar{ background-color:transparent; width:0.5px; height: 6px; border-radius: 5px; }
.scroll-propuesta::-webkit-scrollbar-thumb{ -webkit-box-shadow:inset 0 0 6px transparent; background-color:transparent; border-radius: 5px; }
</style>
