<template>
    <section>
        <tabs :tabs="tabs" class="border-bottom" />
        <router-view />
    </section>
</template>

<script>
export default {
    data(){
        return {
            tabs: [
                { titulo: 'Pedidos Especiales', ruta: 'admin.gaming.pedidos-especiales' },
                { titulo: 'Historial de pedidos', ruta: 'admin.gaming.historial-pedidos' },
                { titulo: 'Premios', ruta: 'admin.gaming.premios'},
                { titulo: 'Eventos', ruta: 'admin.gaming.eventos' },
                { titulo: 'Usuarios', ruta: 'admin.gaming.usuarios' },
                { titulo: 'Recompensas', ruta: 'admin.gaming.recompensas', can:'tab1_gaming_recompensas' }
            ]
        }
    }
}
</script>
