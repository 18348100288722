<template>
    <section>
        <div class="navbar-informativaLogin bg-leeche d-flex d-middle">
            <div class="col-auto px-5" />
            <router-link :to="{name: 'login.form.admin'}" class="none-router text-white cr-pointer">
                <img src="/img/auth/logo_blanco.svg" style="max-width:150px;max-height:80px;" class="ml-4 mr-4" alt="" />
            </router-link>
            <div class="col">
                <footer-faqs />
            </div>
            <p class="f-600 ml-auto text-white mr-5 f-18 cr-pointer" @click="goTo()">
                {{ esLeechero ? 'Leeche': 'Leechero' }}
            </p>
        </div>
        <div class="row mx-0 pt-3 pl-5" style="margin-top:88px;">
            <div class="col-auto px-4" />
            <div class="col-auto px-4">
                <i class="text-leeche icon-left f-22 cr-pointer" @click="$router.back()" />
            </div>
        </div>
        <div class="row mx-0 pl-5">
            <div class="col-auto px-4" />
            <div class="col-3 d-middle-center text-general2 mt-4 f-22 f-600">
                Preguntas Frecuentes
            </div>
            <div class="col-4 mt-4">
                <div class="input" style="width:340px;">
                    <el-input v-model="textoBusqueda" class="w-100 br-20" placeholder="Buscar Pregunta" />
                </div>
            </div>
        </div>
        <div class="overflow-auto custom-scroll" style="height: calc(100vh - 250px)">
            <div v-if="!_.isEmpty(faqsFiltradas)">
                <div v-for="(f, i) in faqsFiltradas" :key="i">
                    <div class="row mx-4 mt-4 pt-4">
                        <p class="mb-0 text-general2 f-600" v-text="f.titulo" />
                    </div>
                    <div v-for="(p, ix) in f.preguntas" :key="`pre-${ix}`" class="my-2">
                        <div class="row mx-4 p-0 border bg-whitesmoke2 cr-pointer" style="border-radius:12px;" @click="colapsar(p)">
                            <p class="mb-0 f-600 text-general2 mx-4 py-2" v-text="p.titulo" />
                            <i v-show="p.texto!=null" class="ml-auto text-general m-2" :class="colocarIconoColapsado(p.colapsado)" />
                        </div>
                        <div v-show="!p.colapsado" class="row mx-3 px-4 my-3" style="text-white: pre-line;" v-text="p.texto" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import faqsInMixin from '~/mixins/faqs/informacion';
export default {
    name: 'PreguntasFrecuentesLogin',
    layout: 'informativaLogin',
    mixins: [faqsInMixin],
    data(){
        return{
            textoBusqueda: ''
        }
    },
    computed: {
        esLeechero(){
            return this.$route.name.match('leechero')
        },
        payload(){
            let destino = this.esLeechero ? 1 : 2
            return {
                tipo: 1,
                destino,
                modoLectura: 1
            }
        },
        faqsFiltradas(){

            return this.datos.faqs.reduce((array, x) => {
                let preguntasIncluidas = []
                if (!_.isEmpty(this.textoBusqueda)){
                    preguntasIncluidas = x.preguntas.filter(p =>
                        this.textoEsCoincidente(p.titulo)(this.textoBusqueda) ||
                        this.textoEsCoincidente(p.texto)(this.textoBusqueda)
                    )
                }

                if (_.isEmpty(this.textoBusqueda)
                    || (this.textoEsCoincidente(x.titulo)(this.textoBusqueda) || !_.isEmpty(preguntasIncluidas))
                ) array.push(x)

                return array
            }, [])
        }
    },
    watch: {
        "$route.name": {
            deep: true,
            handler(){
                this.consultarFaqs(this.payload);
            }
        }
    },
    mounted(){
        this.consultarFaqs(this.payload)
    },
    methods: {
        goTo(){
            let name = this.esLeechero ? 'login.cliente.preguntas-frecuentes' : 'login.leechero.preguntas-frecuentes'
            this.$router.push({ name })
        }
    }}
</script>
<style lang="scss" scoped>

.navbar-informativaLogin{
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1;
    width: calc(100vw);
    height: 88px;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0px 0px 0px 20px;
}
.input{
    .el-input{
        .el-input__inner{
            text-align: center;
            border-radius: 30px !important;
        }
    }
}
</style>
