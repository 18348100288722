import { render, staticRenderFns } from "./estadisticas.vue?vue&type=template&id=08b6c861&scoped=true"
import script from "./estadisticas.vue?vue&type=script&lang=js"
export * from "./estadisticas.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08b6c861",
  null
  
)

export default component.exports