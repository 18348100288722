<template>
    <section>
        <div class="row mx-0 mt-3">
            <div class="col-12 px-0">
                <tabla-general 
                :data="newDatos" 
                class-header="text-general f-16 f-500" 
                alto="calc(100vh - 336px)" 
                :columnas="dataColumns" 
                @filaFuncion="historialRecompensas"
                >
                    <template slot="cabecera-izquierda">
                        <div class="col px-0">
                            <div class="row mx-0">
                                <div class="col-auto text-general f-17 f-500">
                                    {{ $config.cliente }} que tienen saldo en recompensas
                                </div>
                                <div class="col-4 pr-0 ml-auto">
                                    <el-date-picker
                                    v-model="fecha"
                                    class="w-100"
                                    size="small"
                                    type="daterange"
                                    value-format="yyyy-MM-dd"
                                    start-placeholder="Inicio"
                                    end-placeholder="Fin"
                                    @change="listarClientesRecompensas"
                                    />
                                </div>  
                            </div>
                        </div>
                    </template>
                    <template slot="adicionales-derecha">
                        <el-table-column
                        class-name="text-center"
                        width="180"
                        label="Último pedido"
                        prop="fecha_ultimo_pedido"
                        sortable
                        >
                            <template slot-scope="scope">
                                {{ formatearFecha(scope.row.fecha_ultimo_pedido) }}
                            </template>
                        </el-table-column>
                        <el-table-column
                        class-name="text-center"
                        width="180"
                        label="Valor"
                        >
                            <template slot-scope="scope">
                                {{ convertMoney(scope.row.valor_ultimo_pedido,scope.row.idm_moneda) }}
                            </template>
                        </el-table-column>
                        <el-table-column
                        class-name="text-center"
                        width="180"
                        >
                            <template slot="header">
                                <div class="row mx-0 align-items-center">
                                    <img src="/img/gaming/i_moneda.svg" height="20" />
                                    <p class="col-auto text-general f-16">
                                        Monedas
                                    </p>
                                </div>
                            </template>
                            <template slot-scope="scope">
                                {{ scope.row.monedas_actual || 0 }}
                            </template>
                        </el-table-column>
                        <el-table-column
                        class-name="text-center"
                        width="180"
                        >
                            <template slot="header">
                                <div class="row mx-0 align-items-center">
                                    <img src="/img/gaming/i_gema.svg" height="20" />
                                    <p class="col-auto text-general f-16">
                                        Gemas
                                    </p>
                                </div>
                            </template>
                            <template slot-scope="scope">
                                {{ scope.row.gemas_actual || 0 }}
                            </template>
                        </el-table-column>
                        <el-table-column
                        class-name="text-center"
                        width="180"
                        >
                            <template slot="header">
                                <div class="row mx-0 align-items-center">
                                    <img src="/img/gaming/i_moneda.svg" height="20" />
                                    <p class="col-auto text-general f-16">
                                        Redimidas
                                    </p>
                                </div>
                            </template>
                            <template slot-scope="scope">
                                {{ scope.row.monedas_ajuste || 0 }}
                            </template>
                        </el-table-column>
                        <el-table-column
                        class-name="text-center"
                        width="180"
                        >
                            <template slot="header">
                                <div class="row mx-0 align-items-center">
                                    <img src="/img/gaming/i_gema.svg" height="20" />
                                    <p class="col-auto text-general f-16">
                                        Redimidas
                                    </p>
                                </div>
                            </template>
                            <template slot-scope="scope">
                                {{ scope.row.gemas_ajuste || 0 }}
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
            <div class="col-12 d-middle-center bg-white br-b-12 pb-3">
                <div class="bg-general3 text-white cr-pointer mr-2 f-14 br-20 px-2 d-middle-center" :style="`height: 28px; ${newDatos.length == datos.length ? 'pointer-events: none; opacity: 0.5;' : ''}`" @click="paginar(10)">
                    Ver +10
                </div>
                <div class="bg-general3 text-white cr-pointer ml-2 f-14 br-20 px-2 d-middle-center" :style="`height: 28px; ${newDatos.length == datos.length ? 'pointer-events: none; opacity: 0.5;' : ''}`" @click="paginar(50)">
                    Ver +50
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-historial-recompensas ref="historialRecompensas" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import UsuariosRecompensas from '~/services/gaming/gamingUsuariosRecompensas'
export default {
    components: {
        modalHistorialRecompensas: () => import('../partials/modalHistorialRecompensas.vue')
    },
    data(){
        return {
            hasta: 100,
            datos: [],
            newDatos: [],
            fecha: [],
            dataColumns: [
                { titulo: 'Cliente', valor: 'cliente_nombre' },
                { titulo: 'Leechero', valor: 'propietario_nombre' },
                { titulo: 'Cedis', valor: 'cedis_nombre' },
                { titulo: 'ID Últ. pedido', valor: 'id_ultimo_pedido' },
            ],
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
        }),
    },
    watch: {
        async id_cedis(val){
            await this.listarClientesRecompensas()
        },
    },
    mounted(){
        this.dataColumns[0].titulo = this.$config.cliente
        this.dataColumns[1].titulo = this.$config.vendedor
        this.listarClientesRecompensas()
    },
    methods: {
        async listarClientesRecompensas(take=20,offset=this.datos.length){
            try {
                let params = {
                    fecha_filtro: this.fecha
                }
                const { data } = await  UsuariosRecompensas.getClientesRecompensas({params})
                this.datos = data.data
                this.paginar()
                console.log(data)
            } catch (e){
                this.error_catch(e)
            }
        },
        paginar(val=0){
            this.hasta += val
            this.newDatos = this.datos.slice(0,this.hasta)
        },
        historialRecompensas(value){
            this.$refs.historialRecompensas.toggle(value.id_user,1)
        }
    }
}
</script>